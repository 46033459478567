import { ErrorHandler, Injectable } from '@angular/core';

interface IErrorLog {
  time: string;
  routePath: string;
  message: string;
  user: string;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  handleError(error: any): void {
    // console.log('GLOBAL CATCH error:', error);
    // console.error('An error occurred:', error);
    //throw error (Keep this line uncommented in development  in order to see the error in the console)

    this.manageError(error);
  }

  private manageError(error: unknown): void {
    this.logError(error as Error);

    if (error instanceof EvalError) {
      // EvalError: Represents an error regarding the global function eval().
      // Example: This error is rarely encountered in modern JavaScript.
      console.log('EvalError:', error.message);
    } else if (error instanceof RangeError) {
      // RangeError: Represents an error when a numeric variable or parameter is outside its valid range.
      // Example: Trying to create an array with a negative length.
      // let arr = new Array(-1);
      console.log('RangeError:', error.message);
    } else if (error instanceof ReferenceError) {
      // ReferenceError: Represents an error when de-referencing an invalid reference.
      // Example: Accessing an undeclared variable.
      // console.log(nonExistentVariable);
      console.log('ReferenceError:', error.message);
    } else if (error instanceof SyntaxError) {
      // SyntaxError: Represents a syntax error.
      // Example: Attempting to parse invalid JSON.
      // JSON.parse('{"invalidJson": }');
      console.log('SyntaxError:', error.message);
    } else if (error instanceof TypeError) {
      // TypeError: Represents an error when a variable or parameter is not of a valid type.
      // Example: Calling a non-function as a function.
      // null.f();
      console.log(error);
      console.log('TypeError:', error.message);
    } else if (error instanceof URIError) {
      // URIError: Represents an error when encodeURI() or decodeURI() are passed invalid parameters.
      // Example: Trying to decode an invalid URI component.
      // decodeURI('%');
      console.log('URIError:', error.message);
    } else if (error instanceof AggregateError) {
      // AggregateError: Represents several errors wrapped in a single error, for example by Promise.any().
      // Example: Using Promise.any() and all promises reject.
      // let aggregateError = new AggregateError([new Error('Error 1'), new Error('Error 2')], 'Aggregate error occurred');
      console.log('AggregateError:', error.message);
    } else if (error instanceof Error) {
      // Generic Error: Represents a generic error.
      console.log('Error:', error.message);
    } else {
      // Unknown Error: An error of an unknown type.
      console.log('Unknown Error:', error);
    }
  }

  private logError(error: Error): void {
    // Send error to server
    const errorLog: IErrorLog = {
      time: new Date().toISOString(),
      routePath: location.pathname,
      message: error.message,
      user: ''
    };

    // console.log('errorLog:', errorLog);
  }
}
