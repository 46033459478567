import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, Input, OnInit, ViewChild } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { CasinoGamePlayMode, ICasinoGameLaunchDetails } from 'bp-framework/dist/casino/casino.interface';

import { DialogsService } from 'bp-angular-library';

import { CasinoAbstractService } from 'src/app/core/env-specific/env-abstracts';

import { PlatformService } from 'src/app/core/services/platform/platform.service';
import { CasinoService } from 'src/app/pages-casino/casino.service';

@Component({
  selector: 'app-play-game-dialog',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  templateUrl: './play-game-dialog.component.html',
  styleUrl: './play-game-dialog.component.scss'
})
export class PlayGameDialogComponent implements OnInit {
  private dialogsService: DialogsService = inject(DialogsService);
  private platformService: PlatformService = inject(PlatformService);
  private casinoAbstractService: CasinoAbstractService = inject(CasinoAbstractService);
  public casinoService: CasinoService = inject(CasinoService);

  @ViewChild('iframeElRef') iframeElRef!: ElementRef;

  @Input() public gamePlayMode: CasinoGamePlayMode | null = null;

  ngOnInit(): void {
    this.initialize();
  }

  private async initialize(): Promise<void> {
    // const gameId: string | null = this.activatedRoute.snapshot.paramMap.get('gameId');
    const gameId: number | undefined = this.casinoService?.selectedGame$.value?.id;

    // TODO: IMPORTANT: This part of the code has a lot of similarities with the one in the 'game-play-box.component.ts' file. Check if they can be merged or reused from a single place.
    if (!gameId || !this.gamePlayMode) {
      return;
    }

    await this.dialogsService.presentLoading();

    this.casinoAbstractService
      .getDetailsToLaunchGame(gameId, this.gamePlayMode)
      .then((response: ICasinoGameLaunchDetails) => this.updateUrlOnIframeElement(response?.url))
      .catch((error: Error) => {
        // TODO: IMPORTANT: Handle error
        console.log('error');
      })
      .finally(() => this.dialogsService.dismissLoading());
  }

  private updateUrlOnIframeElement(url: string): void {
    if (this.iframeElRef) {
      const nativeElement: HTMLElement = this.iframeElRef.nativeElement;
      nativeElement.setAttribute('src', url);
    }
  }

  public handleClose(): void {
    this.dialogsService.dismissModal();
  }

  public handleLayoutClasses(): string {
    const resolution = this.platformService?.isDesktopResolution() ? 'is-desktop' : 'is-mobile';
    const orientation = this.platformService?.isLandScape() ? 'is-landscape' : 'is-portrait';

    return `${resolution} ${orientation}`?.trim();
  }
}
