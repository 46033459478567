<div class="is-content-wrapper" [ngClass]="handleLayoutClasses()">
  <div class="has-header">
    <ion-title class="is-title">{{ casinoService.selectedGame$.value?.title || '' }}</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="circle" color="cta-tertiary" fill="outline" (click)="handleClose()"><span class="ph ph-x" slot="icon-only"></span></ion-button>
    </ion-buttons>
  </div>
  <ng-container *ngIf="casinoService?.selectedGame$ | async">
    <div class="has-main">
      <ion-content [scrollY]="false">
        <div class="fixed-container">
          <iframe allow="fullscreen" #iframeElRef></iframe>
        </div>
      </ion-content>
    </div>
  </ng-container>
</div>
