import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { OverlayEventDetail } from '@ionic/core';

import { IDialogResponse } from 'bp-framework/dist/dialogs/dialog.interface';

import { AuthenticationService } from '../services/auth/authentication.service';
import { LayoutService } from '../services/layout/layout.service';

import { firstValueFrom, Observable } from 'rxjs';

// Returns a function which can act as a guard for a route
export const guardUntilAuthenticated = (fallbackPath = '/', askToSignIn = false): CanActivateFn => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    return new Promise<boolean | UrlTree>(async (resolve, reject) => {
      // Step 1. Inject dependencies
      const router = inject(Router);
      const authService = inject(AuthenticationService);
      const layoutService = inject(LayoutService);

      const isTokenExpired: boolean = authService.isTokenExpired(); // Checks if the sitename from the URL matches the sitename inside the token

      if (isTokenExpired) {
        if (askToSignIn) {
          const tmpResponse: OverlayEventDetail<IDialogResponse<any>> = await layoutService.showSignInSignUpDialog('sign-in', 'email', true, false);

          if (tmpResponse?.role !== 'confirm') {
            return resolve(router.parseUrl(fallbackPath));
          } else {
            const userdata: any = await firstValueFrom(authService.user$);
            return resolve(true);
          }
        } else {
          await authService.logout();
          // TODO: Make paths as a constant so we can ensure that route paths are referenced by constant name and not hardcoded
          return resolve(router.parseUrl(fallbackPath));
        }
      }

      return resolve(true);
    });
  };
};
