import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { SignInSignUpService } from '../sign-in-sign-up.service';

import { SignUpOptionsButtonsComponent } from './sign-up-options-buttons/sign-up-options-buttons.component';
import { SignUpWithEmailComponent } from './sign-up-with-email/sign-up-with-email.component';
import { SignUpWithTelegramComponent } from './sign-up-with-telegram/sign-up-with-telegram.component';

@Component({
  selector: 'app-sign-up-view',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, SignUpOptionsButtonsComponent, SignUpWithEmailComponent, SignUpWithTelegramComponent],
  templateUrl: './sign-up-view.component.html',
  styleUrl: './sign-up-view.component.scss'
})
export class SignUpViewComponent {
  public siSuService: SignInSignUpService = inject(SignInSignUpService);

  public handleSelected(type: string): void {
    console.log('Selected type:', type);
  }
}
