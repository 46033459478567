import { Injectable } from '@angular/core';

import { BackendEventsAbstractService } from '../../env-abstracts';

@Injectable({
  providedIn: 'root'
})
export class BackendEvents1x2teamService extends BackendEventsAbstractService {
  // TODO: Implement logic that will subscribe to backend events. The 1x2Team backend doesn't have subscriptions or events, so we will probably need to implement some kind of polling mechanism or fetching updates in interval.
  public async subscribeToBackendEvents(): Promise<void> {
    return;
  }
}
