import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-up-with-telegram',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  templateUrl: './sign-up-with-telegram.component.html',
  styleUrl: './sign-up-with-telegram.component.scss'
})
export class SignUpWithTelegramComponent {}
