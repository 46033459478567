import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { ICasinoGame } from 'bp-framework/dist/casino/casino.interface';

import { ImgSrcDirective } from 'src/app/shared/directives/img-src.directive';

@Component({
  selector: 'app-casino-game-thumbnail',
  standalone: true,
  imports: [CommonModule, IonicModule, ImgSrcDirective],
  templateUrl: './casino-game-thumbnail.component.html',
  styleUrl: './casino-game-thumbnail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CasinoGameThumbnailComponent {
  @Input() game!: Partial<ICasinoGame>;
}
