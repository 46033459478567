import { IApiPayload } from 'bp-framework/dist/api/api.interface';

import { IPaginationInfo } from './pagination.interface';

/**
 * Calculates pagination information based on the current offset, limit, and total items.
 *
 * @param {number} currentPageOffset - The current offset of the page.
 * @param {number} limit - The number of items per page.
 * @param {number} total - The total number of items.
 * @returns {PaginationInfo} An object containing pagination details.
 */
export const getPaginationInfo = (apiPayload: IApiPayload<unknown>): IPaginationInfo => {
  // currentPageOffset: number, limit: number, total: number

  const { offset, limit, total } = apiPayload;

  if (typeof offset !== 'number' || typeof limit !== 'number' || typeof total !== 'number') {
    throw new Error('Invalid pagination information');
  }

  // Calculate the current page number based on the current offset and limit
  const currentPage = Math.floor(offset / limit) + 1;

  // Calculate the total number of pages based on the total items and limit
  const totalPages = Math.ceil(total / limit);

  // Determine if the current page is the first page
  const isFirstPage = currentPage === 1;

  // Determine if the current page is the last page
  const isLastPage = currentPage === totalPages;

  // Calculate the offset for the next page
  const nextPageOffset = isLastPage ? offset : offset + limit;

  // Return the pagination information
  return {
    currentPageOffset: offset,
    limit,
    total,
    nextPageOffset,
    pagination: {
      isFirstPage,
      isLastPage,
      currentPage,
      totalPages
    }
  };
};
