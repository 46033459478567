import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { FlagImgComponent } from 'bp-angular-library';

import { MaskitoDirective } from '@maskito/angular';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { maskitoGetCountryFromNumber, maskitoPhoneOptionsGenerator } from '@maskito/phone';
import { CountryCode } from 'libphonenumber-js';
import metadata from 'libphonenumber-js/min/metadata';

@Component({
  selector: 'app-phone-input',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule, MaskitoDirective, FlagImgComponent],
  templateUrl: './phone-input.component.html',
  styleUrl: './phone-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneInputComponent implements OnInit {
  private controlContainer: FormGroupDirective = inject(FormGroupDirective);

  @Input() fcName = 'password';

  private defaultCountryIsoCode: CountryCode = 'DE';

  // TODO: Check here do we need to provide 'countryIsoCode' as a parameter to the maskitoPhoneOptionsGenerator function that matches the country code of the currently selected language or user location.
  public maskObj: MaskitoOptions = maskitoPhoneOptionsGenerator({ metadata, strict: false, countryIsoCode: this.defaultCountryIsoCode });
  readonly maskPredicate: MaskitoElementPredicate = async el => (el as HTMLIonInputElement).getInputElement();

  public formGroup!: FormGroup;

  ngOnInit(): void {
    this.formGroup = this.controlContainer.form;
  }

  protected get countryIsoCode(): string {
    const phoneValue: string = this.formGroup.get(this.fcName)?.value ?? this.defaultCountryIsoCode;

    if (phoneValue) {
      return maskitoGetCountryFromNumber(phoneValue, metadata) ?? this.defaultCountryIsoCode;
    }

    return maskitoGetCountryFromNumber(phoneValue, metadata) ?? this.defaultCountryIsoCode;
  }
}
