/**
 * Returns a comparator function to sort objects by their `sortOrder` property.
 *
 * @template T - The type of objects in the list, which must have an optional `sortOrder` property.
 * @returns {(a: T, b: T) => number} - A comparator function to be used with `Array.prototype.sort`.
 *
 * @example
 * const list = [
 *   { sortOrder: 3, name: 'Item 3' },
 *   { sortOrder: 1, name: 'Item 1' },
 *   { sortOrder: 2, name: 'Item 2' },
 *   { name: 'Item without sortOrder' } // This item has no sortOrder
 * ];
 *
 * const sortedList = list.sort(sortBySortOrder());
 * console.log(sortedList);
 * // Output:
 * // [
 * //   { sortOrder: 1, name: 'Item 1' },
 * //   { sortOrder: 2, name: 'Item 2' },
 * //   { sortOrder: 3, name: 'Item 3' },
 * //   { name: 'Item without sortOrder' }
 * // ]
 */
export function sortBySortOrder<T extends { sortOrder?: number }>() {
  return (a: T, b: T): number => {
    if (a.sortOrder === undefined || b.sortOrder === undefined) {
      return 99999;
    }
    return a.sortOrder - b.sortOrder;
  };
}
