import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';

import { IonicModule, IonSearchbar } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';

import { CasinoService } from '../../../../pages-casino/casino.service';

@Component({
  selector: 'app-casino-searchbar',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule],
  templateUrl: './casino-searchbar.component.html',
  styleUrl: './casino-searchbar.component.scss'
})
export class CasinoSearchbarComponent implements AfterViewInit {
  public casinoService: CasinoService = inject(CasinoService);

  @Output() handleSearchFocus: EventEmitter<void> = new EventEmitter<void>();
  @Output() handleInputChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() public setAutoFocus!: boolean;
  @Input() public inputDebounce = 300;

  @ViewChild('searchbar', { static: false }) searchbar!: IonSearchbar;

  ngAfterViewInit() {
    this.setFocusOnSearchbar();
  }

  setFocusOnSearchbar() {
    if (!this.setAutoFocus) {
      return;
    }

    setTimeout(() => {
      this.searchbar.setFocus();
    }, 300); // Delay to ensure the view is fully initialized
  }

  public handleSearchFocusEvent(): void {
    this.handleSearchFocus.emit();
  }

  public handleInputChangeEvent(event: Event): void {
    this.handleInputChange.emit((event as CustomEvent)?.detail?.value);
  }

  public handleInputClearEvent(): void {
    this.handleInputChange.emit(EMPTY_STRING);
  }
}
