import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-password-input',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, FormsModule, ReactiveFormsModule],
  templateUrl: './password-input.component.html',
  styleUrl: './password-input.component.scss'
})
export class PasswordInputComponent implements OnInit {
  private controlContainer: ControlContainer = inject(ControlContainer);

  @Input() label = 'Password';
  @Input() labelT9nKey = '';
  @Input() placeholder = 'Enter your password';
  @Input() placeholderT9nKey = '';
  @Input() fcName = 'password';

  @Output() handleInputChange: EventEmitter<string> = new EventEmitter<string>();

  public inputType: 'password' | 'text' = 'password';
  public formControl!: FormControl;

  ngOnInit(): void {
    this.formControl = this.controlContainer.control?.get(this.fcName) as FormControl;
  }

  public handleToggleVisibility(): void {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
  }

  public handleIonInputEvent(event: Event): void {
    this.handleInputChange.emit((event as CustomEvent)?.detail?.value);
  }
}
