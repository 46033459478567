import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  standalone: true,
  imports: [],
  templateUrl: './svg-icon.component.html',
  styleUrl: './svg-icon.component.scss'
})
export class SvgIconComponent {
  @Input() imageSrc!: string;
  @Input() fallbackSrc = './svg-icons/plus-circle.svg';

  public handleImgSrcError(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    if (imgElement.src !== this.fallbackSrc) {
      imgElement.src = this.fallbackSrc;
    }
  }
}
