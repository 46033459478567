export const LOBBY_PASS = '1703';
export const EMERGENCY_CODE = '1212';
export const SECRET_KEY_FOR_ACTIVATING_PASS_MODE = '`';
export const WITHDRAW_PIN = '11';

export const SURF_POINT_STORAGE_KEYS = {
  casinoPassword: 'casino_password',
  loginCredentials: 'login_credentials',
  reportQuery: 'report_query',
  gamesSliderLastIndex: 'games_slider_last_index',
  gamesSelectionHistory: 'games_selection_history',
  defaultTerminalId: 'default_terminal_id'
};
