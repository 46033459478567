import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SwUpdate, VersionEvent } from '@angular/service-worker';

import { DialogsService } from 'bp-angular-library';

import { Subscription } from 'rxjs';

// https://web.dev/learn/pwa/update#detecting_service_worker_changes
// https://senoritadeveloper.medium.com/notify-users-with-the-new-version-of-your-angular-app-56813b24d8e3

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private swUpdate: SwUpdate = inject(SwUpdate);
  private dialogsService: DialogsService = inject(DialogsService);

  isNewVersionAvailable = false;
  newVersionSubscription!: Subscription;

  public initialize(): void {
    this.newVersionSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.newVersionSubscription = this.swUpdate.versionUpdates.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((evt: VersionEvent) => this.handleVersionUpdateEvent(evt));
  }

  private handleVersionUpdateEvent(evt: VersionEvent): void {
    switch (evt.type) {
      case 'VERSION_DETECTED':
        // console.log(`Downloading new app version: ${evt.version.hash}`);
        this.dialogsService.presentToast({ message: 'PWA: Downloading new app version...' });
        break;
      case 'VERSION_READY':
        // console.log(`Current app version: ${evt.currentVersion.hash}`);
        // console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
        this.isNewVersionAvailable = true;
        this.dialogsService.presentToast({ message: 'PWA: New app version ready for use' });
        break;
      case 'VERSION_INSTALLATION_FAILED':
        // console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
        this.dialogsService.presentToast({ message: 'PWA: Failed to install new app version' });
        break;
    }
  }
}
