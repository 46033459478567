import { mergeWith } from 'bp-framework/dist/common/common.utils';
import { IEnvApiOnBetPlatform } from 'bp-framework/dist/env-specific/betplatform/configuration/configuration.interface';
import { PROJECT_PREFIX } from 'bp-framework/dist/storage/storage.const';

import { IEnvConfigPlayerFrontend } from 'src/app/shared/models/configuration/configuration.interface';

//#region Base
export const BASE_URLS_DEV = {
  default: 'https://bp.1x2bet.bet'
};

export const BASE_URLS_PROD = {
  default: 'https://bp.app.tasp13.com'
};

export const BASE_API_CONFIG: IEnvApiOnBetPlatform = {
  provider: 'betplatform',
  baseUrls: {
    default: ''
  },
  coreAuthEndpoints: {
    playerLogin: 'auth/login',
    getProfile: 'player/profile',
    adminLogin: ''
  }
};

export const BASE_PROJECT_CONFIG: IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform> = {
  production: false,
  environmentName: 'default',
  projectName: 'bp-player-frontend',
  projectPrefix: PROJECT_PREFIX.playerFrontendApp,
  httpInterceptors: {
    attachAuthTokenToTheseUrls: [],
    doNotAttachAuthTokenToTheseUrls: [],
    skip401HandlingForTheseUrls: []
  },
  api: BASE_API_CONFIG,
  features: {
    frontendMode: 'default',
    storeCredentials: false
  }
};
//#endregion Base

//#region Development Configuration
export const PROJECT_CONFIG_BETPLATFORM_DEV_DEFAULT: IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform> = mergeWith(BASE_PROJECT_CONFIG, {
  environmentName: 'dev',
  httpInterceptors: {
    attachAuthTokenToTheseUrls: [BASE_URLS_DEV.default],
    doNotAttachAuthTokenToTheseUrls: [`${BASE_URLS_DEV.default}/auth/login`],
    skip401HandlingForTheseUrls: [`${BASE_URLS_DEV.default}/auth/refresh`]
  },
  api: {
    baseUrls: {
      default: BASE_URLS_DEV.default
    }
  },
  features: {
    frontendMode: 'default'
  }
} as IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform>);
//#endregion Development Configuration

//#region Stage Configuration
export const PROJECT_CONFIG_BETPLATFORM_STAGE_JUSTCASINO: IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform> = mergeWith(PROJECT_CONFIG_BETPLATFORM_DEV_DEFAULT, {
  environmentName: 'stage',
  features: { frontendMode: 'justcasino' }
} as IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform>);

export const PROJECT_CONFIG_BETPLATFORM_STAGE_SURFPOINT: IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform> = mergeWith(PROJECT_CONFIG_BETPLATFORM_DEV_DEFAULT, {
  environmentName: 'stage',
  features: { frontendMode: 'surfpoint' }
} as IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform>);
//#endregion Stage Configuration

//#region Production Configuration
export const PROJECT_CONFIG_BETPLATFORM_PROD_JUSTCASINO: IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform> = mergeWith(BASE_PROJECT_CONFIG, {
  production: true,
  environmentName: 'prod',
  httpInterceptors: {
    attachAuthTokenToTheseUrls: [BASE_URLS_PROD.default],
    doNotAttachAuthTokenToTheseUrls: [`${BASE_URLS_PROD.default}/auth/login`],
    skip401HandlingForTheseUrls: [`${BASE_URLS_PROD.default}/auth/refresh`]
  },
  api: {
    baseUrls: {
      default: BASE_URLS_PROD.default
    }
  },
  features: {
    frontendMode: 'justcasino'
  }
} as IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform>);

export const PROJECT_CONFIG_BETPLATFORM_PROD_SURFPOINT: IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform> = mergeWith(PROJECT_CONFIG_BETPLATFORM_PROD_JUSTCASINO, {
  features: { frontendMode: 'surfpoint' }
} as IEnvConfigPlayerFrontend<IEnvApiOnBetPlatform>);
//#endregion Production Configuration
