import { Injectable } from '@angular/core';

import { SignUpOptionType, SiSuViewType } from 'src/app/shared/models/ui/ui.interface';

@Injectable({
  providedIn: 'root'
})
export class SignInSignUpService {
  public currentView: SiSuViewType = 'sign-up';
  public signUpOptionType: SignUpOptionType = 'email';
  public reloadAfterSuccess = true;

  public setView(view: SiSuViewType): void {
    this.currentView = view;
  }

  public setSignUpOptionType(view: SignUpOptionType): void {
    this.signUpOptionType = view;
  }
}
