<ion-input
  [formControl]="formControl"
  [attr.aria-label]="labelT9nKey ? (labelT9nKey | translate) : label"
  [placeholder]="placeholderT9nKey ? (placeholderT9nKey | translate) : placeholder"
  fill="outline"
  [type]="inputType"
  (ionInput)="handleIonInputEvent($event)">
  <span slot="start" class="ph ph-lock" aria-hidden="true"></span>
  <ion-button color="cta-tertiary" fill="clear" slot="end" [attr.aria-label]="'commons.showHide' | translate" (click)="handleToggleVisibility()">
    <span slot="icon-only" class="ph ph-eye" aria-hidden="true"></span>
  </ion-button>
</ion-input>
