import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';

import { StorageService } from 'bp-angular-library';

import { filter } from 'rxjs';
import { QUERY_PARAMS_KEYS } from 'src/app/shared/models/routing/routing.const';
import { STORAGE_KEYS } from 'src/app/shared/models/storage/storage.const';

@Injectable({
  providedIn: 'root'
})
export class PromoService {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private router: Router = inject(Router);
  private storageService: StorageService = inject(StorageService);

  public async handleAppInitialization(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.subscribeToRouterEvents();
      resolve(true);
    });
  }

  private subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((e: unknown) => e instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd): void => this.handleNavigationEnd(event));
  }

  private handleNavigationEnd(event: NavigationEnd): void {
    this.handlePromoUrls(event.urlAfterRedirects);
  }

  private handlePromoUrls(urlAfterRedirects: string): void {
    const queryParams = this.getQueryParams(urlAfterRedirects);

    if (queryParams[QUERY_PARAMS_KEYS.bannerTag]) {
      this.storageService.setLocalItem(STORAGE_KEYS.bannerTag, queryParams[QUERY_PARAMS_KEYS.bannerTag]);
    }
  }

  private getQueryParams(urlAfterRedirects: string): { [key: string]: string } {
    const url = new URL(urlAfterRedirects, window.location.origin); // Use a dummy base URL to parse relative URLs
    const queryParams: { [key: string]: string } = {};

    url.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams;
  }
}
