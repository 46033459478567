import { IListItem } from 'bp-framework/dist/common/common.interface';
import { LANGUAGE_ALPHA2CODE } from 'bp-framework/dist/i18n/i18n.const';

export const SUPPORTED_LANGUAGE_OPTIONS: Partial<IListItem<string>>[] = [
  {
    value: LANGUAGE_ALPHA2CODE.en,
    label: '',
    labelT9nKey: 'languages.en.label',
    imgSrc: ''
  },
  {
    value: LANGUAGE_ALPHA2CODE.de,
    label: '',
    labelT9nKey: 'languages.de.label',
    imgSrc: ''
  },
  {
    value: LANGUAGE_ALPHA2CODE.sr,
    label: '',
    labelT9nKey: 'languages.sr.label',
    imgSrc: ''
  },
  {
    value: LANGUAGE_ALPHA2CODE.zh,
    label: '',
    labelT9nKey: 'languages.zh.label',
    imgSrc: ''
  }
];
