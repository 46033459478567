import { Directive, ElementRef, HostListener, inject, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dirImgSrc]',
  standalone: true
})
export class ImgSrcDirective {
  private el: ElementRef = inject(ElementRef);
  private renderer: Renderer2 = inject(Renderer2);

  @Input() fallbackImgSrc!: string;
  @Input() placeholderImgSrc = './images/img-error-placeholder.jpg';

  private hasTriedFallback = false;
  private hasTriedPlaceholder = false;

  @HostListener('error')
  onError() {
    this.handleError();
  }

  private handleError() {
    if (!this.hasTriedFallback && this.fallbackImgSrc) {
      this.hasTriedFallback = true;
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.fallbackImgSrc);
    } else if (!this.hasTriedPlaceholder && this.placeholderImgSrc) {
      this.hasTriedPlaceholder = true;
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.placeholderImgSrc);
    }
  }
}
