import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { IListItem } from 'bp-framework/dist/common/common.interface';

import { CasinoService } from '../../../../pages-casino/casino.service';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoProvidersService {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private casinoService: CasinoService = inject(CasinoService);

  public allProvidersFromBackend$: BehaviorSubject<Partial<IListItem<number>>[]> = new BehaviorSubject<Partial<IListItem<number>>[]>([]);

  constructor() {
    this.initialize();
  }

  private async initialize(): Promise<void> {
    this.casinoService?.allProvidersUnderGroup$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: Partial<IListItem<number>>[]) => {
      this.allProvidersFromBackend$.next(value);
    });
  }
}
