import { Injectable } from '@angular/core';

import { BettingAbstractService } from '../../env-abstracts';

@Injectable({
  providedIn: 'root'
})
export class Betting1x2teamService extends BettingAbstractService {
  public test(): void {
    console.log('test');
  }
}
