import { inject, Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Platforms } from '@ionic/core';

import { Observable } from 'rxjs';
import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from 'src/app/shared/models/ui/ui.const';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private platform: Platform = inject(Platform);

  // TODO: Check how can we have a better way to handle changes in the screen size and orientation.
  // In future, we might have folding phones and larger mobile screens. Therefore, we need to have a better way to define what is a mobile, tablet, and desktop resolution/device.
  // So the 'mobile', 'tablet', and 'desktop' resolutions might not be enough to decide when to render 'mobile-ui' vs 'desktop-ui'.

  //#region Utility methods
  public isPlatform(type: Platforms): boolean {
    return this.platform.is(type);
  }

  public isLandScape(): boolean {
    return this.platform.isLandscape();
  }

  // TODO: Investigate how can we use different meta tags and manifest properties for different platforms that will improve better user experience on different devices and operating systems.
  // For example, we can use the following meta tags for different platforms:
  // https://github.com/vercel/next.js/issues/70272
  // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/MetaTags.html
  // https://github.com/gokulkrishh/awesome-meta-and-manifest
  // https://web.dev/learn/html/metadata
  // https://dev.to/pssingh21/pwa-metadata-in-manifest-json-3j2m
  // https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariWebContent/ConfiguringWebApplications/ConfiguringWebApplications.html
  // https://gist.github.com/hal0gen/5852bd9db240c477f20c
  // https://dev.to/oncode/display-your-pwa-website-fullscreen-4776
  public isPortrait(): boolean {
    return this.platform.isPortrait();
  }

  public isMobileResolution(): boolean {
    return this.platform?.width() < TABLET_BREAKPOINT;
  }

  public isTabletResolution(): boolean {
    return this.platform?.width() >= TABLET_BREAKPOINT && this.platform?.width() < DESKTOP_BREAKPOINT;
  }

  public isDesktopResolution(): boolean {
    return this.platform?.width() >= DESKTOP_BREAKPOINT;
  }
  //#endregion

  public subscribeOnResize(): Observable<void> {
    return this.platform.resize.asObservable();
  }

  public width(): number {
    return this.platform.width();
  }

  public height(): number {
    return this.platform.height();
  }

  public platformReady(): Promise<string> {
    return this.platform.ready();
  }
}
