import { Routes } from '@angular/router';

import { INavGroup, INavigationItem } from 'bp-framework/dist/common/common.interface';

import { ROUTES_FOR_MY_PROFILE, ROUTES_FOR_RULES, ROUTES_FOR_SUPPORT, SITE_FOOTER_NAVIGATION } from './routes.utils';
import { ROUTE_PATHS } from 'src/app/shared/models/routing/routing.const';

export const BETPLATFORM_DEV_DEFAULT_ROUTES: Routes = [
  //#region Main
  {
    path: ROUTE_PATHS.login,
    loadComponent: () => import('../../../../pages/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: ROUTE_PATHS.signUp,
    loadComponent: () => import('../../../../pages/sign-up/sign-up.component').then(m => m.SignUpComponent)
  },
  {
    path: ROUTE_PATHS.landing,
    loadComponent: () => import('../../../../pages/landing/landing.component').then(m => m.LandingComponent)
  },
  {
    path: ROUTE_PATHS.betting,
    loadComponent: () => import('../../../../pages-betting/betting/betting.component').then(m => m.BettingComponent)
  },
  {
    path: ROUTE_PATHS.bettingLive,
    loadComponent: () => import('../../../../pages-betting/betting-live/betting-live.component').then(m => m.BettingLiveComponent)
  },
  {
    path: ROUTE_PATHS.casino,
    loadChildren: () => import('../../../../pages-casino/casino.routes').then(r => r.CASINO_ROUTES)
  },
  {
    path: ROUTE_PATHS.casinoLive,
    loadComponent: () => import('../../../../pages-casino-live/casino-live.component').then(m => m.CasinoLiveComponent)
  },
  {
    path: ROUTE_PATHS.greekKeno,
    loadComponent: () => import('../../../../pages-keno/keno/keno.component').then(m => m.kenoComponent)
  },
  {
    path: ROUTE_PATHS.virtualSports,
    loadComponent: () => import('../../../../pages-virtual/virtual-sports/virtual-sports.component').then(m => m.VirtualSportsComponent)
  },
  {
    path: ROUTE_PATHS.virtualGames,
    loadComponent: () => import('../../../../pages-virtual/virtual-games/virtual-games.component').then(m => m.VirtualGamesComponent)
  },
  //#endregion Main
  //#region My Profile
  ...ROUTES_FOR_MY_PROFILE,
  //#endregion My Profile
  //#region Support
  ...ROUTES_FOR_SUPPORT,
  //#endregion Support
  //#region Rules
  ...ROUTES_FOR_RULES,
  //#endregion Rules
  {
    path: 'casino-game-iframe-error',
    redirectTo: ROUTE_PATHS.notFound,
    pathMatch: 'full'
  },
  {
    path: ROUTE_PATHS.notFound,
    loadComponent: () => import('../../../../pages/not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: 'theme',
    loadComponent: () => import('../../../../pages/theme-testing/theme-testing.component').then(m => m.ThemeTestingComponent)
  },
  {
    path: '',
    redirectTo: ROUTE_PATHS.casino, // Default page
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: ROUTE_PATHS.notFound
  }
];

export const MAIN_HEADER_LINKS_FOR_BETPLATFORM: Partial<INavigationItem<number>>[] = [
  { labelT9nKey: 'pages.betting.title', value: 1, routePath: `/${ROUTE_PATHS.betting}` },
  { labelT9nKey: 'pages.bettingLive.title', value: 2, routePath: `/${ROUTE_PATHS.bettingLive}` },
  { labelT9nKey: 'pages.casino.title', value: 3, routePath: `/${ROUTE_PATHS.casino}` },
  { labelT9nKey: 'pages.casinoLive.title', value: 4, routePath: `/${ROUTE_PATHS.casinoLive}` },
  { labelT9nKey: 'pages.virtualSports.title', value: 5, routePath: `/${ROUTE_PATHS.virtualSports}` },
  { labelT9nKey: 'pages.virtualGames.title', value: 7, routePath: `/${ROUTE_PATHS.virtualGames}` }
];

export const BETPLATFORM_DEV_DEFAULT_SIDEMENU: Partial<INavGroup>[] = [
  {
    path: `/${ROUTE_PATHS.betting}`,
    label: 'Betting',
    t9nKey: 'pages.betting.title',
    sortOrder: 1,
    icon: 'ph-soccer-ball'
  },
  {
    path: `/${ROUTE_PATHS.bettingLive}`,
    label: 'Live Betting',
    t9nKey: 'pages.bettingLive.title',
    sortOrder: 2,
    icon: 'ph-soccer-ball'
  },
  {
    path: `/${ROUTE_PATHS.casino}`,
    label: 'Casino',
    t9nKey: 'pages.casino.title',
    sortOrder: 3,
    icon: 'ph-club'
  },
  {
    path: `/${ROUTE_PATHS.casinoLive}`,
    label: 'Live Casino',
    t9nKey: 'pages.casinoLive.title',
    sortOrder: 4,
    icon: 'ph-club'
  },
  {
    path: `/${ROUTE_PATHS.virtualSports}`,
    label: 'Grčki kino',
    t9nKey: 'pages.greekKeno.title',
    sortOrder: 4,
    icon: 'ph-fire-simple'
  }
];

export const BETPLATFORM_DEV_DEFAULT_SITE_FOOTER_NAVIGATION: Partial<INavGroup>[] = SITE_FOOTER_NAVIGATION;
