import { ParamMap } from '@angular/router';

import { IQueryParamsMap } from './routing.interface';

export const returnItemIndexIfQueryParamsIdMatchingFromList = (
  currentItemValue: number | undefined,
  listOfAllItems: { value?: number }[],
  queryParams: IQueryParamsMap,
  paramKey: any
): number | undefined => {
  const queryParamValue = Number(queryParams[paramKey]);

  if (typeof queryParamValue !== 'number' || queryParamValue === currentItemValue) {
    return;
  }

  return listOfAllItems.findIndex((listItem: { value?: any }) => listItem?.value === queryParamValue);
};

/**
 * Converts a ParamMap to an IQueryParamsMap.
 * @param paramMap The ParamMap to convert.
 * @returns The converted IQueryParamsMap.
 */
export const convertParamMapToQueryParamsMap = (paramMap: ParamMap): IQueryParamsMap => {
  return paramMap.keys.reduce((acc: IQueryParamsMap, key: string) => {
    acc[key] = paramMap.get(key);
    return acc;
  }, {});
};
