import { CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';

import { IonicModule, ModalController } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { DIALOG_DISMISS_ROLES } from 'bp-framework/dist/dialogs/dialogs.const';

import { SignInSignUpService } from './sign-in-sign-up.service';

import { BrandLogoComponent } from '../../layout/brand-logo/brand-logo.component';
import { SignInViewComponent } from './sign-in-view/sign-in-view.component';
import { SignUpViewComponent } from './sign-up-view/sign-up-view.component';

import { SignUpOptionType, SiSuViewType } from 'src/app/shared/models/ui/ui.interface';

@Component({
  selector: 'app-sign-in-sign-up',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, BrandLogoComponent, SignInViewComponent, SignUpViewComponent],
  templateUrl: './sign-in-sign-up.component.html',
  styleUrl: './sign-in-sign-up.component.scss'
})
export class SignInSignUpComponent implements OnInit {
  private modalController: ModalController = inject(ModalController);
  public siSuService: SignInSignUpService = inject(SignInSignUpService);

  @Input() showHeader = true;
  @Input() currentView: SiSuViewType = 'sign-in';
  @Input() signUpOptionType: SignUpOptionType = 'email';
  @Input() reloadAfterSuccess = true;

  ngOnInit(): void {
    this.siSuService.setView(this.currentView);
    this.siSuService.setSignUpOptionType(this.signUpOptionType);
    this.siSuService.reloadAfterSuccess = this.reloadAfterSuccess;
  }

  public handleClose(): void {
    this.modalController.dismiss(null, DIALOG_DISMISS_ROLES.cancel);
  }
}
