import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { ICasinoGamesAndTags, ICasinoGamesGroup, ICasinoSearchParams, ICasinoTag } from 'bp-framework/dist/casino/casino.interface';
import { IListItem } from 'bp-framework/dist/common/common.interface';
import { IBpPayload } from 'bp-framework/dist/env-specific/betplatform/api/api.interface';
import { IBpCasinoSearchParams, IBpCasinoTag, IBpGamesWithTags } from 'bp-framework/dist/env-specific/betplatform/casino/casino.interface';
import { mapCasinoGames } from 'bp-framework/dist/env-specific/betplatform/casino/casino.mappers';

export const adjustParamsForBetplatform = (params: ICasinoSearchParams): IBpCasinoSearchParams => {
  const tmpParams: IBpCasinoSearchParams = {
    offset: params?.offset,
    limit: params?.limit,
    query: params?.query,
    tag_title: params?.tagTitle,
    type: params?.tagType,
    tag_ids: Array.isArray(params?.tagIds) ? params.tagIds : undefined,
    vendor: params?.vendor
  };

  return tmpParams;
};

export const transformCasinoTagToGroupItem = (response: IApiPayload<Partial<ICasinoTag>[]>): IApiPayload<Partial<ICasinoGamesGroup<number>>[]> => {
  const tmpPayload: IApiPayload<Partial<ICasinoGamesGroup<number>>[]> = {
    data:
      response?.data
        ?.map((item: Partial<ICasinoTag>) => {
          const tmpItem: Partial<ICasinoGamesGroup<number>> = {
            value: item?.id || 0,
            label: item?.title || '',
            imgSrc: item?.imageUrl || '',
            sortOrder: item?.sortOrder,
            config: {
              launchUrl: item?.config?.launchUrl,
              groupId: item?.groupId
            }
          };

          return tmpItem;
        })
        ?.sort(sortByOrderAndLabel) || [],
    offset: response?.offset,
    limit: response?.limit,
    total: response?.total
  };

  return tmpPayload;
};

export const transformCasinoTagToListItem = (response: IApiPayload<Partial<ICasinoTag>[]>): IApiPayload<Partial<IListItem<number>>[]> => {
  const sortedData =
    response?.data
      ?.map((item: Partial<ICasinoTag>) => {
        const tmpListItem: Partial<IListItem<number>> = {
          value: item?.id || 0,
          label: item?.title || '',
          imgSrc: item?.imageUrl || '',
          sortOrder: item?.sortOrder
        };

        return tmpListItem;
      })
      ?.sort(sortByOrderAndLabel) || [];

  return {
    data: sortedData,
    offset: response?.offset,
    limit: response?.limit,
    total: response?.total
  };
};

export const transformPayloadToCasinoGamesAndTags = (response: IBpPayload<IBpGamesWithTags> | null): IApiPayload<ICasinoGamesAndTags> => {
  const tmpResponse: IApiPayload<ICasinoGamesAndTags> = {
    data: {
      games: response?.data?.games && Array.isArray(response?.data?.games) ? mapCasinoGames(response?.data?.games as any) : [],
      tags:
        response?.data?.tags && Array.isArray(response?.data?.tags)
          ? response?.data?.tags
              ?.map((tag: IBpCasinoTag) => {
                const tmpTag: ICasinoTag = {
                  id: tag?.id,
                  type: tag?.type,
                  title: tag?.title,
                  imageUrl: tag?.image_url,
                  parentId: tag?.parent_id,
                  sortOrder: tag?.sort_order,
                  groupId: tag?.group_id
                };
                return tmpTag;
              })
              .sort(sortByOrderAndTitle)
          : []
    },
    offset: response?.offset,
    limit: response?.limit,
    total: response?.total
  };

  return tmpResponse;
};

export const transformPayloadToCasinoTags = (response: IBpPayload<IBpCasinoTag[]> | null): IApiPayload<ICasinoTag[]> => {
  return {
    data:
      response?.data
        ?.map((item: IBpCasinoTag) => {
          return {
            id: item.id,
            title: item.title,
            imageUrl: item.image_url,
            type: item.type,
            sortOrder: item.sort_order,
            groupId: item.group_id,
            config: {
              launchUrl: item.config?.launch_url,
              groupId: item.group_id
            }
          } as ICasinoTag;
        })
        .sort(sortByOrderAndTitle) || [],
    offset: response?.offset,
    limit: response?.limit,
    total: response?.total
  };
};

export const sortByOrderAndTitle = (a: ICasinoTag, b: ICasinoTag): number => {
  if (a.sortOrder !== b.sortOrder) {
    return (a.sortOrder || 0) - (b.sortOrder || 0);
  }
  return (a.title || '').localeCompare(b.title || '');
};

export const sortByOrderAndLabel = (a: Partial<IListItem<number>>, b: Partial<IListItem<number>>): number => {
  if (a.sortOrder !== b.sortOrder) {
    return (a.sortOrder || 0) - (b.sortOrder || 0);
  }
  return (a.label || '').localeCompare(b.label || '');
};
