<form *ngIf="formGroup" [formGroup]="formGroup">
  <ng-container *ngIf="maskObj?.mask">
    <ion-input
      [formControlName]="fcName"
      [maskito]="maskObj"
      [maskitoElement]="maskPredicate"
      [attr.aria-label]="'commons.phone' | translate"
      fill="outline"
      [placeholder]="'commons.phone' | translate"
      debounce="300">
      <lib-flag-img slot="start" [style.border-radius.%]="50" [countryIsoAlpha2Code]="countryIsoCode"></lib-flag-img>
    </ion-input>
  </ng-container>
</form>
