import { IListItem } from 'bp-framework/dist/common/common.interface';

/**
 * Updates the selection state of providers based on a list with updated selections.
 *
 * @param {Partial<IListItem<string>>[]} listWithUpdatedSelections - The list containing updated selection states.
 * @param {Partial<IListItem<string>>[]} allProviders - The list of all providers.
 * @returns {Partial<IListItem<string>>[]} - The list of all providers with updated selection states.
 */
export const updateProviderSelections = (listWithUpdatedSelections: Partial<IListItem<string>>[], allProviders: Partial<IListItem<string>>[]): Partial<IListItem<string>>[] => {
  // Create a map to store the selection state of each item in listWithUpdatedSelections
  const selectionMap = new Map<string, boolean>();

  // Populate the map with the selection states
  listWithUpdatedSelections.forEach(item => {
    if (item.value) {
      selectionMap.set(item.value, item.selected || false);
    }
  });

  // Update the selection state of each item in allProviders based on the map
  return allProviders.map(provider => {
    if (provider.value && selectionMap.has(provider.value)) {
      provider.selected = selectionMap.get(provider.value);
    }
    return provider;
  });
};

/**
 * Filters a list of providers by a keyword.
 *
 * @param {string} keyword - The keyword to filter by.
 * @param {Partial<IListItem<number>>[]} list - The list of providers.
 * @returns {Partial<IListItem<number>>[]} - The filtered list of providers.
 */
export const filterProviderLabelsByKeyword = (keyword: string, list: Partial<IListItem<number>>[]): Partial<IListItem<number>>[] => {
  return list.filter(item => item.label?.toLowerCase().includes(keyword.toLowerCase()));
};
