import { Routes } from '@angular/router';

import { INavGroup } from 'bp-framework/dist/common/common.interface';

import { MAIN_ROUTE_PATHS } from 'src/app/shared/models/routing/routing.const';

export const ALL_ROUTES_FOR_1X2TEAM: Routes = [
  //#region Main
  {
    path: MAIN_ROUTE_PATHS.login,
    loadComponent: () => import('../../../../pages/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.landing,
    loadComponent: () => import('../../../../pages/landing/landing.component').then(m => m.LandingComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.betting,
    loadComponent: () => import('../../../../pages-betting/betting/betting.component').then(m => m.BettingComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.bettingLive,
    loadComponent: () => import('../../../../pages-betting/betting-live/betting-live.component').then(m => m.BettingLiveComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.casino,
    loadChildren: () => import('../../../../pages-casino/casino.routes').then(r => r.CASINO_ROUTES)
  },
  {
    path: MAIN_ROUTE_PATHS.casinoLive,
    loadComponent: () => import('../../../../pages-casino-live/casino-live.component').then(m => m.CasinoLiveComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.virtualSports,
    loadComponent: () => import('../../../../pages-virtual/virtual-sports/virtual-sports.component').then(m => m.VirtualSportsComponent)
  },
  {
    path: MAIN_ROUTE_PATHS.virtualGames,
    loadComponent: () => import('../../../../pages-virtual/virtual-games/virtual-games.component').then(m => m.VirtualGamesComponent)
  },
  //#endregion Main
  {
    path: MAIN_ROUTE_PATHS.notFound,
    loadComponent: () => import('../../../../pages/not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: 'theme',
    loadComponent: () => import('../../../../pages/theme-testing/theme-testing.component').then(m => m.ThemeTestingComponent)
  },
  {
    path: '',
    redirectTo: MAIN_ROUTE_PATHS.landing, // Default page
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: MAIN_ROUTE_PATHS.notFound
  }
];

export const MAIN_HEADER_LINKS_FOR_1X2TEAM: Partial<INavGroup>[] = [
  { t9nKey: 'pages.betting.title', sortOrder: 1, path: `/${MAIN_ROUTE_PATHS.betting}` },
  { t9nKey: 'pages.bettingLive.title', sortOrder: 2, path: `/${MAIN_ROUTE_PATHS.bettingLive}` },
  { t9nKey: 'pages.casino.title', sortOrder: 3, path: `/${MAIN_ROUTE_PATHS.casino}` },
  { t9nKey: 'pages.casinoLive.title', sortOrder: 4, path: `/${MAIN_ROUTE_PATHS.casinoLive}` },
  { t9nKey: 'pages.virtualSports.title', sortOrder: 5, path: `/${MAIN_ROUTE_PATHS.virtualSports}` },
  { t9nKey: 'pages.virtualGames.title', sortOrder: 7, path: `/${MAIN_ROUTE_PATHS.virtualGames}` }
];

export const SIDEMENU_NAV_GROUPS_FOR_1X2TEAM: Partial<INavGroup>[] = [
  {
    path: MAIN_ROUTE_PATHS.home,
    label: 'Home',
    t9nKey: '',
    sortOrder: 0,
    icon: 'ph-house',
    children: []
  },
  {
    path: MAIN_ROUTE_PATHS.betting,
    label: 'Betting',
    t9nKey: 'pages.betting.title',
    sortOrder: 1,
    icon: 'ph-soccer-ball'
  },
  {
    path: MAIN_ROUTE_PATHS.bettingLive,
    label: 'Live Betting',
    t9nKey: 'pages.bettingLive.title',
    sortOrder: 2,
    icon: 'ph-soccer-ball'
  },
  {
    path: MAIN_ROUTE_PATHS.casino,
    label: 'Casino',
    t9nKey: 'pages.casino.title',
    sortOrder: 3,
    icon: 'ph-club'
  },
  {
    path: MAIN_ROUTE_PATHS.casinoLive,
    label: 'Live Casino',
    t9nKey: 'pages.casinoLive.title',
    sortOrder: 4,
    icon: 'ph-club'
  }
];
