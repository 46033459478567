import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { ICasinoGame, ICasinoGamesAndTags } from 'bp-framework/dist/casino/casino.interface';

import { DialogsService } from 'bp-angular-library';

import { CasinoAbstractService } from 'src/app/core/env-specific/env-abstracts';

import { CasinoService } from '../../../../pages-casino/casino.service';
import { RouteManagementService } from 'src/app/core/services/route-management/route-management.service';

import { CasinoGameThumbnailComponent } from '../casino-game-thumbnail/casino-game-thumbnail.component';
import { CasinoSearchbarComponent } from '../casino-searchbar/casino-searchbar.component';

import { from, Subject, switchMap } from 'rxjs';

@Component({
  selector: 'app-casino-search-modal',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, CasinoSearchbarComponent, CasinoGameThumbnailComponent],
  templateUrl: './casino-search-modal.component.html',
  styleUrl: './casino-search-modal.component.scss'
})
export class CasinoSearchModalComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  private casinoAbstractService: CasinoAbstractService = inject(CasinoAbstractService);
  private router: Router = inject(Router);
  private routeManagementService: RouteManagementService = inject(RouteManagementService);

  private dialogsService: DialogsService = inject(DialogsService);
  private casinoService: CasinoService = inject(CasinoService);
  public allCasinoGames: Partial<ICasinoGame>[] = [];
  private keyword$: Subject<string> = new Subject<string>();

  @Input() showHeader = true;

  ngOnInit(): void {
    this.subscribeToKeyworkChanges();
  }

  public handleInputChange(value: string): void {
    // TODO: IMPORTANT: Find a way how to reply or cache the search results for same keyword in order to avoid unnecessary API calls

    if (value.length < 3) {
      this.allCasinoGames = [];
      return;
    }

    this.keyword$.next(value);
  }

  public async handleClose(): Promise<boolean> {
    return this.dialogsService.dismissModal();
  }

  private subscribeToKeyworkChanges(): void {
    this.keyword$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        // distinctUntilChanged(), // Only trigger when the input value changes
        switchMap((keyword: string) => from(this.casinoAbstractService.getCasinoGames({ query: keyword })))
      )
      .subscribe((payload: IApiPayload<ICasinoGamesAndTags>) => {
        this.allCasinoGames = payload?.data?.games || [];
      });
  }

  public async handleGameSelectedEvent(game: Partial<ICasinoGame>): Promise<void> {
    this.allCasinoGames = [];

    this.casinoService.selectedGame$.next(game);
    await this.handleClose();
    this.routeManagementService.navigateToCasinoView('preview', game?.id);
  }
}
