import { inject, Injectable } from '@angular/core';

import { IApiPayload } from 'bp-framework/dist/api/api.interface';
import { ITransactionGroup } from 'bp-framework/dist/transactions/transaction-groups.interface';

import { BetPlatformApiAdapterService } from 'bp-angular-library';

import { GlobalAbstractService } from '../../env-abstracts';

@Injectable({
  providedIn: 'root'
})
export class GlobalBetplatformService extends GlobalAbstractService {
  private bpApiAdapterService: BetPlatformApiAdapterService = inject(BetPlatformApiAdapterService);

  financeGetTransactionGroups(): Promise<IApiPayload<ITransactionGroup[]> | null> {
    return this.bpApiAdapterService.getFinanceTransactionGroups();
  }
}
