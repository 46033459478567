import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { EMERGENCY_CODE, LOBBY_PASS } from '../models/surfpoint.const';
import { Observable } from 'rxjs';

// Returns a function which can act as a guard for a route
export const guardNavigationCodes = (): CanActivateFn => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    return new Promise<boolean | UrlTree>(async (resolve, reject) => {
      // Step 1. Inject dependencies
      const router = inject(Router);

      const param: any = route?.queryParams['lobbyPass'];

      if (param === EMERGENCY_CODE) {
        // TODO: In here we need a mechanism to block the player or all players under the agent. The emergency code is a mechanism to prevent breach
        return resolve(router.parseUrl('surfpoint/lobby'));
      }

      if (param !== LOBBY_PASS) {
        return resolve(router.parseUrl('surfpoint/lobby'));
      }

      return resolve(true);
    });
  };
};
