import { IQueryParamsKeys } from './routing.interface';

export const MAIN_ROUTE_PATHS = {
  notFound: 'not-found',
  login: 'login',
  home: 'home',
  landing: 'landing',
  betting: 'betting',
  bettingLive: 'betting-live',
  casino: 'casino',
  casinoLive: 'casino-live',
  greekKeno: 'greek-keno',
  virtualSports: 'virtual-sports',
  virtualGames: 'virtual-games'
};

export const PLAYER_ROUTE_ROOT_SEGMENT = 'player';

export const PLAYER_ROUTE_CHILD_SEGMENTS = {
  dashboard: 'dashboard',
  betSlips: 'bet-slips',
  transactions: 'transactions',
  details: 'details'
};

// TODO: We should refactor this into something that should also contain the 'label', 't9nKey', 'sortOrder', 'icon' properties. We want to keep things reusabel and consistent.
export const ROUTE_PATHS = {
  notFound: 'not-found',
  login: 'login',
  signUp: 'sign-up',
  home: 'home',
  landing: 'landing',
  betting: 'betting',
  bettingLive: 'betting-live',
  casino: 'casino',
  casinoLive: 'casino-live',
  greekKeno: 'greek-keno',
  virtualSports: 'virtual-sports',
  virtualGames: 'virtual-games',
  myProfile: {
    path: 'my-profile',
    children: {
      myAccount: 'account',
      deposit: 'deposit',
      withdraw: 'withdraw',
      myTransactions: 'transactions',
      myAffiliate: 'affiliate',
      myVerification: 'verification',
      myPasswordChange: 'password-change',
      support: 'support'
    }
  },
  support: {
    path: 'support',
    children: {
      contact: 'contact',
      privacyPolicy: 'privacy-policy',
      termsOfUse: 'terms-of-use',
      responsibleGamingPolicy: 'responsible-gaming-policy',
      amlKycPolicy: 'aml-kyc-policy'
    }
  },
  rules: {
    path: 'rules',
    children: {
      casinoRules: 'casino',
      liveCasinoRules: 'live-casino',
      virtualSportsRules: 'virtual-sports'
    }
  }
};

export const QUERY_PARAMS_KEYS: IQueryParamsKeys = {
  siSuViewType: 'siSuViewType', // Sign in sign up view type
  signUpOptionType: 'signUpOptionType', // Sign up option type
  bannerTag: 'bt', // Affiliate code or banner tag
  casinoGroupId: 'casinoGroupId',
  casinoSubCategoryId: 'casinoSubCategoryId',
  vendorId: 'vendorId',
  keyword: 'keyword'
};
