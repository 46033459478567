import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ICasinoGamesGroup } from 'bp-framework/dist/casino/casino.interface';
import { processTextForMultiLineLabel } from 'bp-framework/dist/common/common.utils';

import { CasinoService } from '../../../../pages-casino/casino.service';

@Injectable({
  providedIn: 'root'
})
export class CasinoCategoriesStripeService {
  private router: Router = inject(Router);
  private casinoService: CasinoService = inject(CasinoService);

  public groups: Partial<ICasinoGamesGroup<number>>[] = [];

  constructor() {
    this.initialize();
  }

  private initialize(): void {
    this.casinoService.allCasinoGroups$.subscribe((groups: Partial<ICasinoGamesGroup<number>>[]) => {
      this.groups = groups;
    });
  }

  public handleSelectedGroupChange(group: Partial<ICasinoGamesGroup<number>>): void {
    if (group?.config?.launchUrl) {
      this.router.navigateByUrl(`/${group.config.launchUrl}`);
      return;
    }

    this.casinoService.selectedProvider$.next(null);
    this.casinoService.selectedSubCategory$.next(null);
    this.casinoService.selectedGroup$.next(group);
  }
}
