import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { IonicModule, ModalController } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { EMPTY_STRING } from 'bp-framework/dist/common/common.const';
import { DIALOG_DISMISS_ROLES } from 'bp-framework/dist/dialogs/dialogs.const';
import { IUserDetails } from 'bp-framework/dist/user/user.interface';

import { DialogsService } from 'bp-angular-library';

import { UserAbstractService } from 'src/app/core/env-specific/env-abstracts';

import { SignInSignUpService } from '../sign-in-sign-up.service';

import { PasswordInputComponent } from '../../password-input/password-input.component';

@Component({
  selector: 'app-sign-in-view',
  standalone: true,
  imports: [CommonModule, IonicModule, TranslateModule, PasswordInputComponent, FormsModule, ReactiveFormsModule],
  templateUrl: './sign-in-view.component.html',
  styleUrl: './sign-in-view.component.scss'
})
export class SignInViewComponent {
  public siSuService: SignInSignUpService = inject(SignInSignUpService);
  private userAbstractService: UserAbstractService = inject(UserAbstractService);
  private dialogsService: DialogsService = inject(DialogsService);
  private modalController: ModalController = inject(ModalController);
  private formBuilder: FormBuilder = inject(FormBuilder);
  private formIsSubmitted = false;

  public formGroup!: FormGroup<{
    username: FormControl<string | null>;
    password: FormControl<string | null>;
  }>;

  //#region lifecycle hooks
  ngOnInit(): void {
    this.initializeForm();
  }
  //#endregion

  //#region methods
  private initializeForm(): void {
    this.formGroup = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  public async handleSubmit(): Promise<void> {
    if (!this.formGroup.valid || this.formIsSubmitted) {
      return;
    }

    // TODO: IMPORTANT: This is a temporary solution to prevent multiple form submissions. This should be handled in a more elegant way
    this.formIsSubmitted = true;

    const username: string = this.formGroup.controls.username.value || EMPTY_STRING;
    const password: string = this.formGroup.controls.password.value || EMPTY_STRING;

    await this.dialogsService.presentLoading();

    this.userAbstractService
      .loginWithUsernameAndPassword(username, password)
      .then((user: Partial<IUserDetails> | null) => this.handleLoginSuccess(user))
      .catch((error: Error) => {
        this.dialogsService.presentToast({ message: error?.message, color: 'danger' });
      })
      .finally(() => {
        this.dialogsService.dismissLoading();
        this.formIsSubmitted = false;
      });
  }
  //#endregion

  public handleLoginSuccess = (user: Partial<IUserDetails> | null): void => {
    this.modalController.dismiss(user, DIALOG_DISMISS_ROLES.confirm);
    // TODO: Check if this is really neaded. The initial reason for this was the need to reload the page after login on the 1x2team provider

    if (this.siSuService.reloadAfterSuccess) {
      window.location.reload();
    }
  };
}
