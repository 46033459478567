import { inject, Injectable } from '@angular/core';
import { Routes } from '@angular/router';

import { IListItem, INavGroup, IPrimaryPageItem } from 'bp-framework/dist/common/common.interface';

import { PROJECT_ENV_CONFIG_TOKEN } from 'bp-angular-library';

import { ContentAbstractService } from '../../env-abstracts';

import { BETPLATFORM_DEV_SURFPOINT_ROUTES } from '../../betplatform/routes/routes.dev.surfpoint';
import { ALL_ROUTES_FOR_1X2TEAM, MAIN_HEADER_LINKS_FOR_1X2TEAM, SIDEMENU_NAV_GROUPS_FOR_1X2TEAM } from '../routes/routes.1x2team';
import { IEnvApiBase, IEnvConfigPlayerFrontend } from 'src/app/shared/models/configuration/configuration.interface';

@Injectable({
  providedIn: 'root'
})
export class Content1x2teamService extends ContentAbstractService {
  private projectConfig: IEnvConfigPlayerFrontend<IEnvApiBase> = inject<IEnvConfigPlayerFrontend<IEnvApiBase>>(PROJECT_ENV_CONFIG_TOKEN);

  public async getListOfPrimaryPages(): Promise<Partial<IPrimaryPageItem<number>>[]> {
    return Promise.resolve([]);
  }

  public buildAllRoutes(): Routes {
    if (this.projectConfig?.features?.frontendMode === 'surfpoint') {
      // TODO: Implement adapter instead of using BetPlatform routes
      return BETPLATFORM_DEV_SURFPOINT_ROUTES;
    } else {
      return ALL_ROUTES_FOR_1X2TEAM;
    }
  }

  public buildSidemenuRoutes(): Partial<INavGroup>[] {
    return SIDEMENU_NAV_GROUPS_FOR_1X2TEAM;
  }

  public buildMainMenuRoutes(): Partial<INavGroup>[] {
    return MAIN_HEADER_LINKS_FOR_1X2TEAM;
  }

  public buildSiteFooterNavLinks(): Partial<INavGroup>[] {
    return [];
  }

  public buildSecondaryPagesNavLinks(): Partial<INavGroup>[] {
    return [];
  }
}
